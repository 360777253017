import Vue from "vue";
import "./page";
import Modules from "./modules";
import "./images.js";
import "../scss/style.scss";
import "./components";

const main = async () => {
  if (process.env.NODE_ENV === "production") {
    // Manually force Vue to be in prod mode.
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
  }
  new Vue({
    el: "#app",
  });
};

main().then(() => {
  Modules();
});
